import styled from "styled-components";
import fotoMainImg from "../assets/images/background-servicos2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import CardOrcamento from "./CardOrcamento";
import logo from "../assets/images/Logotipo 3.png";

const LogoContainer = styled.div`
  background: url(${(props) => props.fotoMainImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 250vh;
  margin-top: -15%;
  color: #ececec;
  @media only screen and (max-width: 600px) {
    height: 300vh;
  }
  
`;
const MainContainer = styled.div`
  padding-top: 16%;
  margin-left: 8%;
  margin-right: 8%;
`;

const Titulo = styled.div`
  font: 600 60px "Montserrat-Bold", sans-serif;
  color: #fa4616;
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const Texto = styled.div`
  padding-top: 2%;
  width: 70%;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;
const CardGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 4%;
  margin-top: 5%;
  text-align: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-top: 0;
  }
`;

const TextoLink = styled.a`
  color: #fa4616;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function ServicosMain() {
  AOS.init();

  return (
    <LogoContainer fotoMainImg={fotoMainImg}>
      <MainContainer>
        <Titulo data-aos="fade-down" data-aos-duration="1500">
          {" "}
          Nossos Serviços
        </Titulo>
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Oferecemos diversos serviços para que você agilizar a sua operação em
          diversas frentes.
        </Texto>
        <br />
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Serviços como:
        </Texto>

        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardOrcamento
            imagem={logo}
            titulo="Carga fracionada"
            texto="O volume da sua entrega não é grande? Sem problema, você pode enviar pequenas quantidades."
            url="https://api.whatsapp.com/send?phone=558596907171&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20ao%20servi%C3%A7o%20:Carga%20fracionada"
          />
          <CardOrcamento
            titulo="Carga fechada"
            texto="Feito pra você que entrega em grande quantidades e precisa de um valor especial."
            imagem={logo}
            url="https://api.whatsapp.com/send?phone=558596907171&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20ao%20servi%C3%A7o%20:"
          />
          <CardOrcamento
            titulo="Serviço de coleta"
            texto="Pegamos a sua carga em qualquer local combinado."
            imagem={logo}
            url="https://api.whatsapp.com/send?phone=558596907171&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20ao%20servi%C3%A7o%20:"
          />
          <CardOrcamento
            titulo="Aluguel de Empilhadeiras"
            texto="Além do transporte você também precisa organizar sua em carga no depósito? Conte com a gente."
            imagem={logo}
            url="https://api.whatsapp.com/send?phone=558596907171&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20ao%20servi%C3%A7o%20:"
          />
        </CardGroup>
        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardOrcamento
            titulo="Munque"
            texto="Oferecemos serviços com caminhões equipados com o munque."
            imagem={logo}
            url="https://api.whatsapp.com/send?phone=558591215760&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20ao%20servi%C3%A7o%20:"
          />
          <CardOrcamento
            titulo="Operação portuária"
            texto="Recolhemos containeres em diversos portos."
            imagem={logo}
            url="https://api.whatsapp.com/send?phone=558596907171&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20ao%20servi%C3%A7o%20:"
          />
          
        </CardGroup>
        <br />
        <br />
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Não encontrou o que procurava?
        </Texto>
        <Texto data-aos="fade-down" data-aos-duration="1500">
          <TextoLink
            target="_blank"
            href="https://api.whatsapp.com/send?phone=558596907171&text=N%C3%A3o%20encontrei%20o%20servi%C3%A7o%20que%20procurava."
          >
            Converse conosco
          </TextoLink>
          , somos flexíveis, podemos encontrar a melhor forma de atender o seu
          negócio.
        </Texto>
      </MainContainer>
    </LogoContainer>
  );
}

export { ServicosMain };
export default ServicosMain;
