import { Nav } from "../components";
import { MainSection } from "../components";
import { ThirdSection } from "../components";
import { FourthSection } from "../components";
import { FifthSection } from "../components";
import { ContactSection } from "../components";
import { Footer } from "../components";
function HomePage() {
  return (
    <>
      <Nav />
      <MainSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <ContactSection />
      <Footer />
    </>
  );
}

export { HomePage };
export default HomePage;
