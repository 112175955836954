import styled from "styled-components";
import click from "../assets/images/click-icon.png";
import AOS from "aos";
import "aos/dist/aos.css";
import frota1 from "../assets/images/frota1.png";
import frota2 from "../assets/images/frota2.png";
import frota3 from "../assets/images/frota3.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Main = styled.div`
  background: #232323;
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  padding: 0px 128px 0px 128px;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-Bold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px 32px 0px 32px;
    height: 100vh;
  }
`;
const LadoEsquerdo = styled.div`
  width: 50%;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const LadoDireito = styled.div`
  width: 50%;
  margin-left: 10%;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const Titulo = styled.div`
  width: 60%;
  font: 600 60px "Montserrat-Bold", sans-serif;
  color: #fa4616;
  @media (min-width: 1537px) {
    
  }

  @media only screen and (max-width: 600px) {
    font-size: 38px;
  }
`;
const Texto = styled.div`
  padding-top: 20px;
  width: 70%;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    width: 100%;
  }
`;

const SaibaMais = styled.a`
  text-decoration: none;
  display: flex;
  color: #ececec;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  margin-top: 40px;
  width: 24%;
  height: 24%;
  &:hover {
    cursor: pointer;
    color: #fa4616;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
const Click = styled.img`
  width: 30px;
  padding-top: 15px;
  margin-left: -5px;

  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 25px;
  }
`;

function FifthSection() {
  AOS.init();

  return (
    <>
      <Main>
        <LadoEsquerdo data-aos="flip-right" data-aos-duration="1500">
          <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay>
            <div>
              <img src={frota1} alt="foto frota 1" />
            </div>
            <div>
              <img src={frota2} alt="foto frota 2" />
            </div>
            <div>
              <img src={frota3} alt="foto frota 3" />
            </div>
          </Carousel>
        </LadoEsquerdo>

        <LadoDireito data-aos="flip-left" data-aos-duration="1000">
          <Titulo>Frota</Titulo>
          <Texto>
            Com a gente você pode contar com uma frota variada que com certeza
            terá a capacidade de atender completamente seu negócio.
          </Texto>
          <SaibaMais href="/frotas">
            Descubra
            <Click src={click} />
          </SaibaMais>
        </LadoDireito>
      </Main>
    </>
  );
}

export { FifthSection };
export default FifthSection;
