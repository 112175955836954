import { Nav } from "../components";
import { SetoresMain } from "../components";
import { Footer } from "../components";
function ServicosPage() {
  return (
    <>
      <Nav />
      <SetoresMain />
      <Footer />
    </>
  );
}

export { ServicosPage };
export default ServicosPage;
