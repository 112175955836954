import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const CardStyle = styled.div`
  padding: 1%;
  border: 6px solid #fa4616;
  border-radius: 4%;
  width: 14vw;
  height: 35vh;

  @media only screen and (max-width: 600px) {
    width: 80%;
    height: auto;
    margin-top: 16px;
  }
`;
const Image = styled.img`
  width: 40%;
  @media only screen and (max-width: 600px) {
    width: 20%;
  }
`;

const TextoCard = styled.div`
  font: 400 22px "Montserrat-SemiBold", sans-serif;
  color: #fa4616;
  margin-top: 10%;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

function CardSetores(props) {
  AOS.init();

  return (
    <>
      <CardStyle>
        <Image src={props.imagem} />
        <TextoCard>{props.texto}</TextoCard>
      </CardStyle>
    </>
  );
}

export { CardSetores };
export default CardSetores;
