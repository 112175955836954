import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const CardStyle = styled.div`
  padding: 1%;
  border: 6px solid #fa4616;
  border-radius: 4%;
  width: 14vw;
  height: 35vh;
  display: flex;
  flex-direction: column;
`;
const Image = styled.img`
  width: 100%;
  margin: auto;
`;

const BotaoTipoFrota = styled.button`
  font: 400 100% "Montserrat-SemiBold", sans-serif;
  border: 1px solid #fa4616;
  background-color: #fa4616;
  color: #232323;
  padding: 5%;
  margin-bottom: -7%;
  margin-left: -7%;
  text-decoration: none;
  width: 115%;
  &:hover {
    transition: 250ms;
    color: #ececec;
  }
`;

function CardFrotas(props) {
  AOS.init();

  return (
    <>
      <CardStyle>
        <Image src={props.imagem} />
        <BotaoTipoFrota target="_blank">{props.texto}</BotaoTipoFrota>
      </CardStyle>
    </>
  );
}

export { CardFrotas };
export default CardFrotas;
