import styled from "styled-components";
import fotoBg from "../assets/images/bg-secao2.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Background = styled.div`
  background: url(${(props) => props.fotoBg});
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 100%; */
  height: 100vh;
  color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 128px 0px 128px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 16px 32px 16px 32px;
    background-size: cover;
  }
`;

//Lado esquerdo
const LadoEsquerdo = styled.div`
  width: 50%;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 2rem;

  }
`;

const TituloEsquerda = styled.div`
  width: 50%;
  font: 600 60px "Montserrat-Bold", sans-serif;
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 38px;
  }
`;

const TextoEsquerda = styled.div`
  width: 65%;
  font: 400 100% "Montserrat-SemiBold", sans-serif;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;


//Lado direito
const LadoDireito = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: right;
  /* position: relative; */
  /* top: 20%;
  left: 5%; */
  @media (min-width: 1537px) {
    /* top: 8%; */
  }

  @media only screen and (max-width: 600px) {
    width: 100%;

  }
`;

const TopicoDireita = styled.span`
  font: 600 100% "Montserrat-Bold", sans-serif;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 600 32px "Montserrat-Bold", sans-serif;
  }
`;
const TextoInternoTopico = styled.div`
  font: 400 100% "Montserrat-SemiBold", sans-serif;
  width: 80%;
  padding-top: 1%;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 400 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

function ThirdSection() {
  AOS.init();

  return (
    <Background fotoBg={fotoBg}>
      <LadoEsquerdo data-aos="flip-left" data-aos-duration="1000">
        <TituloEsquerda>Para nos conhecer</TituloEsquerda>
        <TextoEsquerda>
          Somos uma empresa tradicionalmente <strong>cearense</strong> com mais
          de 10 anos de experiência.
          <br />
          <br />
          Atuamos oferecendo nossos serviços para todo o Brasil, buscando sempre
          a agilidade e excelência.
          <br />
          <br />
          Fique tranquilo, conte conosco!
        </TextoEsquerda>
      </LadoEsquerdo>
      <LadoDireito data-aos="flip-right" data-aos-duration="1000">
        <TopicoDireita>
          Missão
          <TextoInternoTopico>
            Oferecer soluções logísticas eficientes, seguras e ágeis, com alto
            padrão de qualidade e satisfação do cliente.
          </TextoInternoTopico>
        </TopicoDireita>
        <br />
        <br />
        <TopicoDireita>
          Visão
          <TextoInternoTopico>
            Ser referência em excelência no mercado de transportes e logística,
            buscando sempre a satisfação total de seus clientes e colaboradores.
          </TextoInternoTopico>
        </TopicoDireita>
        <br />
        <br />
        <TopicoDireita>
          Valores
          <TextoInternoTopico>
            Comprometimento, ética, excelência, inovação e sustentabilidade.
          </TextoInternoTopico>
        </TopicoDireita>
        <br />
        <br />
      </LadoDireito>
    </Background>
  );
}

export { ThirdSection };
export default ThirdSection;
