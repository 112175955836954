import { Nav } from "../components";
import { ServicosMain } from "../components";
import { Footer } from "../components";
function ServicosPage() {
  return (
    <>
      <Nav />
      <ServicosMain />
      <Footer />
    </>
  );
}

export { ServicosPage };
export default ServicosPage;
