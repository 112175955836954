import styled from "styled-components";
import fotoBgLogo from "../assets/images/Smbolo_3.png";
import fotoAvenida from "../assets/images/umit-yildirim-gvylxjb1jZg-unsplash.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Background = styled.div`
  background-color: #fa4616;
  height: 80vh;
  color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 128px 0px 128px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px 32px 0px 32px;
    height: 100vh;
  }
`;

const ImgLogo = styled.div`
  background: url(${(props) => props.fotoBgLogo});
  background-repeat: no-repeat;
  width: 80%;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  left: -8%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ImgAvenida = styled.div`
  background: url(${(props) => props.ImgAvenida});
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  background-size: cover;
  opacity: 0.1;
  position: absolute;
`;
//Lado esquerdo
const LadoEsquerdo = styled.div`
  width: 50%;
  margin-top: -10%;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const TituloEsquerda = styled.div`
  width: 50%;
  font: 600 70px "Montserrat-Bold", sans-serif;
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 38px;
  }
`;
const TextoEsquerda = styled.div`
  width: 65%;
  font: 600 20px "Montserrat-SemiBold", sans-serif;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    width: 100%;
    margin-bottom: 4rem;
  }
`;

//Lado direito
const LadoDireito = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1537px) {
    /* top: 8%; */
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const BotaoContato = styled.a`
  border-radius: 50px;
  background-color: #232323;
  font: 600 40px "Montserrat-SemiBold", sans-serif;
  padding: 30px;
  color: #fa4616;
  text-decoration: none;
  cursor: pointer;
  width: 60%;
  text-align: center;
  &:hover {
    text-decoration: none;
    color: #ececec;
    transition: 250ms;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    width: 90%;
    padding: 10px;
  }
  
`;

function ContactSection() {
  AOS.init();

  return (
    <Background id="contatos">
      <ImgLogo fotoBgLogo={fotoBgLogo}></ImgLogo>
      <ImgAvenida ImgAvenida={fotoAvenida}></ImgAvenida>
      <LadoEsquerdo data-aos="flip-left" data-aos-duration="1000">
        <TituloEsquerda>Contato</TituloEsquerda>
        <br />

        <TextoEsquerda>
          Ligação? WhatsApp? E-mail? Qual forma de contato você mais usa na sua
          rotina? Na LOC você pode usar as 3.
          <br />
          <br />
          Escolha uma das opções ao lado e venha bater um papo com um de nossos
          especialistas!
        </TextoEsquerda>
      </LadoEsquerdo>

      <LadoDireito data-aos="flip-right" data-aos-duration="1000">
        <BotaoContato href="tel:+558596907171">Ligar</BotaoContato>
        <br />
        <BotaoContato
          target="_blank"
          href="https://api.whatsapp.com/send?phone=+558596907171&text=Olá,%20vim%20através%20do%20site%20e%20quero%20agendar%20um%20serviço..."
          rel="noreferrer"
        >
          WhatsApp
        </BotaoContato>
        <br />
        <BotaoContato href="mailto:comercial@loclogistica.com.br">Enviar e-mail</BotaoContato>
      </LadoDireito>
    </Background>
  );
}

export { ContactSection };
export default ContactSection;
