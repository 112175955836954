import styled from "styled-components";
import fotoMainImg from "../assets/images/MainImgPaisagem.png";
import AOS from "aos";
import "aos/dist/aos.css";
import ButtonBudget from "./ButtonBudget";

const LogoContainer = styled.div`
  background: url(${(props) => props.fotoBgSection1});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 0;
  color: #ececec;
  margin-top: -15%

`;

const TextOnMiddle = styled.div`
  /* position: relative;
  top: 31%;
  left: 27%;
  width: 47%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  font: 400 161% "Montserrat-SemiBold", sans-serif;

  @media only screen and (max-width: 600px) {
    font-size: 13px;

  }
`;

const Title = styled.h1`
  width: 70%;
  margin-top: 10%;
`

function MainSection() {
  AOS.init();

  return (
    <LogoContainer fotoBgSection1={fotoMainImg} id="sobre">
      <TextOnMiddle data-aos="fade-down" data-aos-duration="1000">
        <Title>
          Fique tranquilo, confiando nas soluções da <b>LOC</b>!
        </Title>
        <a
          href="https://api.whatsapp.com/send?phone=558596907171&text=Ol%C3%A1.%20Estou%20no%20site%20da%20LOC%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento."
          target="_blank"
          rel="noreferrer"
        >
          <ButtonBudget />
        </a>
      </TextOnMiddle>
    </LogoContainer>
  );
}

export { MainSection };
export default MainSection;
