import { Nav } from "../components";
import { FrotaMain } from "../components";
import { Footer } from "../components";
function FrotaPage() {
  return (
    <>
      <Nav />
      <FrotaMain />
      <Footer />
    </>
  );
}

export { FrotaPage };
export default FrotaPage;
