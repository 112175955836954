import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const CardStyle = styled.div`
  padding: 1%;
  border: 6px solid #fa4616;
  border-radius: 4%;
  max-width: 15vw;
`;
const Image = styled.img`
  width: 40%;
`;

const TextoCard = styled.div`
  font: 400 18px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  margin-bottom: 8%;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-Bold", sans-serif;
  }
`;

function Card(props) {
  AOS.init();

  return (
    <>
      <CardStyle>
        <Image src={props.imagem} />
        <TextoCard>{props.texto}</TextoCard>
      </CardStyle>
    </>
  );
}

export { Card };
export default Card;
