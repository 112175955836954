import styled from "styled-components";
import videoBg from "../assets/videos/fundo-video-sect4.mp4";
import videoBgMobile from "../assets/videos/fundo-video-sect4-mobile.mp4";

import click from "../assets/images/click-icon.png";
import AOS from "aos";
import "aos/dist/aos.css";

import { useTamanhoTela } from "../hooks/useTamanhoTela";

const Background = styled.div`
  position: absolute;
  z-index: -1;
  max-height: 80vh;
  video {
    max-width: 100%;
    max-height: 100%;
  }

  @media only screen and (max-width: 600px) {
    /* height: 100%;
    width: 100%; */
    /* max-height: 150vh; */

    video {
      min-width: 100%;
      min-height:100%;
      height: 150%;
    }

  }
`;

const LadoEsquerdo = styled.div`
  width: 50%;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const LadoDireito = styled.div`
  width: 50%;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Titulo = styled.div`
  width: 60%;
  font: 600 50px "Montserrat-Bold", sans-serif;
  color: #fa4616;
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 38px;
  }
`;
const Texto = styled.div`
  padding-top: 20px;
  width: 70%;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-top: 0px;
    font: 400 20px "Montserrat-SemiBold", sans-serif;

  }
`;


const Main = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0px 128px 0px 128px;
  @media (min-width: 1537px) {
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px 32px 0px 32px;
  }
`;

const SaibaMais = styled.a`
  text-decoration: none;
  display: flex;
  color: #ececec;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  margin-top: 40px;
  width: 24%;
  height: 24%;
  &:hover {
    cursor: pointer;
    color: #fa4616;
  }
`;
const Click = styled.img`
  width: 30px;
  padding-top: 15px;
  margin-left: -5px;

  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 25px;
  }
`;

function FourthSection() {
  AOS.init();

  const { isMobile, isTablet } = useTamanhoTela()

  const videoBackground = () => {
    if(isMobile || isTablet) {
      return videoBgMobile
    } else {
      return videoBg
    }
  }

  return (
    <>
      <Background>
        <video muted autoplay="true" loop src={videoBackground()}></video>
      </Background>
      <Main>
        <LadoEsquerdo data-aos="flip-left" data-aos-duration="1000">
          <Titulo>Nossos Serviços</Titulo>
          <Texto>
            Oferecemos diversos serviços para que você possa agilizar a sua
            operação em diversas frentes.
          </Texto>
          <SaibaMais href="/servicos">
            Conheça
            <Click src={click} />
          </SaibaMais>
        </LadoEsquerdo>

        <LadoDireito data-aos="flip-right" data-aos-duration="1500">
          <Titulo>Setores de atuação</Titulo>
          <Texto>
            Buscamos atender a maior gama de setores da indústria e do comércio,
            tendo sempre conhecimento da operação.
          </Texto>
          <SaibaMais href="/setores">
            Confira
            <Click src={click} />
          </SaibaMais>
        </LadoDireito>
      </Main>
    </>
  );
}

export { FourthSection };
export default FourthSection;
