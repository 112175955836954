import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const CardStyle = styled.div`
  padding: 1%;
  border: 6px solid #fa4616;
  border-radius: 4%;
  max-width: 15vw;
  height: 20rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: 100%;
    /* width: 100%; */
    margin-top: 16px;
    max-width: none;
  }
`;
const Image = styled.img`
  width: 40%;
`;

const TituloCard = styled.div`
  font: 600 28px "Montserrat-Bold", sans-serif;
  color: #fa4616;
  /* word-break: break-all; */
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
const TextoCard = styled.div`
  font: 400 18px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  margin-bottom: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const ButtonBudget = styled.a`
  font: 400 100% "Montserrat-SemiBold", sans-serif;
  border: 3px solid #fa4616;
  border-radius: 25px;
  background-color: #fa4616;
  color: #232323;
  padding: 3px 30px;
  /* position: absolute; */
  /* margin: 0 0 0 -6.1%; */
  margin-top: 1rem;
  text-decoration: none;
  &:hover {
    transition: 250ms;
    color: #ececec;
  }
`;

function CardOrcamento(props) {
  AOS.init();

  return (
    <>
      <CardStyle>
        <Image src={props.imagem} />
        <TituloCard>{props.titulo}</TituloCard>
        <TextoCard>
          {props.texto}
          <ButtonBudget target="_blank" href={props.url}>
            Orçamento
          </ButtonBudget>
        </TextoCard>
       
      </CardStyle>
    </>
  );
}

export { CardOrcamento };
export default CardOrcamento;
