import styled from "styled-components";
import fotoBg from "../assets/images/background-2-2.png";
import fotoWhatsApp from "../assets/images/whatsapp.png";
import fotoInstagram from "../assets/images/instagram.png";
import fotoFacebook from "../assets/images/facebook.png";
import fotoMulherComTablet from "../assets/images/mulher-com-tablet.png";
import AOS from "aos";
import "aos/dist/aos.css";

const LogoContainer = styled.div`
  background: url(${(props) => props.fotoBgSection2});
  background-repeat: no-repeat;
  background-size: 100% 80%;
  width: 100%;
  height: 80vh;
  color: #ececec;
`;
const TextFindUsDiv = styled.div`
  color: #fa4616;
  font: 800 330% "Montserrat-SemiBold", sans-serif;
  position: absolute;
  top: 21%;
  left: 18%;
  max-width: 82%;
`;
const TextRedesSociais = styled.div`
  font: 600 120% "Montserrat-SemiBold", sans-serif;
  position: absolute;
  top: 29%;
  left: 24%;
  max-width: 82%;
`;

const LogosRedesSociais = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 38%;
  left: 38%;
  max-width: 60%;

  //1920x1080
  /* Media query para telas menores que 480px */
  @media (min-width: 400px) and (max-width: 800px) {
    img {
      width: 7%; /* limite de largura da imagem para telas muito pequenas */
    }
  }
  @media (min-width: 1367px) and (max-width: 1920px) {
    img {
      width: 9%; /* limite de largura da imagem para telas muito pequenas */
    }
  }
  @media (min-width: 1920px) {
    img {
      width: 12%; /* limite de largura da imagem para telas muito pequenas */
    }
  }
`;
const MulherComTablet = styled.img`
  transform: scaleX(-1);
  position: relative;
  top: 9%;
  left: 63%;
  width: 27%;
  @media (min-width: 400px) and (max-width: 800px) {
    top: 40%;
    left: 63%;
  }
  @media (min-width: 1920px) {
    top: -24%;
    left: 63%;
  }
`;

const LogosRedesSociaisIndividual = {
  paddingBottom: "3%",
};

function SecondSection() {
  AOS.init();

  return (
    <LogoContainer
      fotoBgSection2={fotoBg}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <TextFindUsDiv data-aos="fade-down" data-aos-duration="2000">
        Encontre-nos
      </TextFindUsDiv>
      <TextRedesSociais data-aos="fade-down" data-aos-duration="2000">
        nas redes sociais
      </TextRedesSociais>
      <LogosRedesSociais data-aos="fade-down" data-aos-duration="2000">
        <img
          src={fotoWhatsApp}
          style={LogosRedesSociaisIndividual}
          width="8%"
          alt="foto WhatsApp"
        />
        <img
          src={fotoInstagram}
          style={LogosRedesSociaisIndividual}
          width="8%"
          alt="foto Instagram"
        />
        <img
          src={fotoFacebook}
          style={LogosRedesSociaisIndividual}
          width="8%"
          alt="foto Facebook"
        />
      </LogosRedesSociais>
      <MulherComTablet
        data-aos="fade-down"
        data-aos-duration="2000"
        src={fotoMulherComTablet}
        alt="Mulher Com Tablet"
      ></MulherComTablet>
    </LogoContainer>
  );
}

export { SecondSection };
export default SecondSection;
