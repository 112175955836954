import logo from "../assets/images/Logotipo 3.png";
import styled from "styled-components";

const NavBar = styled.div`
  display: flex;
  top: 0;
  position: fixed;
  border-bottom: solid 2px #fa4616;
  background-color: #00000061;
  font: 400 27px "Montserrat-SemiBold", sans-serif;
  z-index: 1;
`;

const LinkLogo = styled.a`
  margin-left: 8%;
  max-width: 40%;
`;

const LogoTop = styled.img`
  width: 35%;
  alt: "Foto da logomarca da LOC";
  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-top: 8%;
  }
`;

const LinksNav = styled.div`
  display: flex;
  /* grid-template-columns: 30% 25% 25% 25%; */
  /* grid-gap: 5%; */
  /* gap: 10%; */
  margin: auto;
  justify-content: space-between;
  /* margin-right: 17%; */

  a {
    text-decoration: none;
    color: #ececec;

    &:hover {
      color: #fa4616;
      transition: 250ms;
    }
  }
`;

const LinkNav = styled.a`
margin: 8px;

@media only screen and (max-width: 600px) {
    font-size: 13px;

}

`

export default function Nav() {
  return (
    <NavBar>
      <LinkLogo href="/">
        <LogoTop src={logo} />
      </LinkLogo>
      <LinksNav>
        
          <LinkNav href="/#sobre">Sobre</LinkNav>
        
        
          <LinkNav href="/servicos">Serviços</LinkNav>
        
        
          <LinkNav href="/setores">Setores</LinkNav>
         
        
          <LinkNav href="/#contatos">Contato</LinkNav>
        
      </LinksNav>
    </NavBar>
  );
}
