import styled from "styled-components";
import fotoMainImg from "../assets/images/background-servicos2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import CardFrotas from "./CardFrotas";
import frota01 from "../assets/images/frota01.png";
import frota02 from "../assets/images/frota02.png";
import frota03 from "../assets/images/frota03.png";
import frota04 from "../assets/images/frota04.png";
import frota05 from "../assets/images/frota05.png";
import frota06 from "../assets/images/frota06.png";
import frota07 from "../assets/images/frota07.webp";
import frota08 from "../assets/images/frota08.webp";
import frota09 from "../assets/images/frota09.png";
import frota10 from "../assets/images/frota10.png";
import frota11 from "../assets/images/frota11.png";
import frota12 from "../assets/images/frota12.webp";
import frota13 from "../assets/images/frota13.png";
import frota14 from "../assets/images/frota14.png";

const LogoContainer = styled.div`
  background: url(${(props) => props.fotoMainImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 300vh;
  color: #ececec;
`;
const MainContainer = styled.div`
  padding-top: 10%;
  margin-left: 8%;
`;

const Titulo = styled.div`
  font: 600 60px "Montserrat-Bold", sans-serif;
  color: #fa4616;
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }
`;
const Texto = styled.div`
  padding-top: 2%;
  width: 70%;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }
`;
const CardGroup = styled.div`
  display: flex;
  gap: 4%;
  margin-top: 5%;
  text-align: center;
`;

const ButtonBudget = styled.button`
  font: 500 200% "Montserrat-SemiBold", sans-serif;
  border: 3px solid #fa4616;
  border-radius: 30px;
  background-color: #fa4616;
  padding: 6px 30px;
  width: 45%;
  margin-top: 15%;
  margin-left: 22%;
  a {
    text-decoration: none;
    color: #ececec;
  }
  &:hover {
    cursor: pointer;
    a {
      color: #232323;
      transition: 200ms;
    }
  }
`;

function FrotaMain() {
  AOS.init();

  return (
    <LogoContainer fotoMainImg={fotoMainImg}>
      <MainContainer>
        <Titulo data-aos="fade-down" data-aos-duration="1500">
          {" "}
          Frota
        </Titulo>
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Contamos com uma frota diversa pronta para lhe atender.
        </Texto>

        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardFrotas imagem={frota01} texto="Carretas graneleiras 12M" />
          <CardFrotas imagem={frota02} texto="Carretas carga seca 12M e 14M" />
          <CardFrotas imagem={frota03} texto="Carretas forgão 3 eixos" />
          <CardFrotas imagem={frota04} texto="Carretas sider" />
        </CardGroup>
        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardFrotas imagem={frota05} texto="Carretas frigoríficas" />
          <CardFrotas imagem={frota06} texto="Carretas extensivas 12M e 30M" />
          <CardFrotas imagem={frota07} texto="Carretas prancha baixa 12M" />
          <CardFrotas imagem={frota08} texto="Truck plataforma" />
        </CardGroup>
        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardFrotas imagem={frota09} texto="Truck toco" />
          <CardFrotas imagem={frota10} texto="Truck 2 eixos carga seca" />
          <CardFrotas imagem={frota11} texto="Truck 2 eixos forgão" />
          <CardFrotas imagem={frota12} texto="Empilhadeira" />
        </CardGroup>
        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardFrotas imagem={frota13} texto="Carro pequeno 3/4" />
          <CardFrotas imagem={frota14} texto="Truck munk 12 á 30TON" />
        </CardGroup>
        <br />

        <ButtonBudget>
          {" "}
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=558596907171&text=Gostaria%20de%20um%20or%C3%A7amento%20referente%20a%20frotas%20"
          >
            Peça um orçamento{" "}
          </a>
        </ButtonBudget>
      </MainContainer>
    </LogoContainer>
  );
}

export { FrotaMain };
export default FrotaMain;
