import styled from "styled-components";

const BotaoOrcamento = styled.button`
  font: 400 100% "Montserrat-SemiBold", sans-serif;
  border: 3px solid #fa4616;
  border-radius: 25px;
  background-color: transparent;
  color: #ececec;
  padding: 3px 30px;
  margin-top: 7%;
  &:hover {
    background-color: #fa4616;
    cursor: pointer;
    transition: 200ms;
  }
`;

function ButtonBudget() {
  return <BotaoOrcamento>Peça um orçamento</BotaoOrcamento>;
}
export { ButtonBudget };
export default ButtonBudget;
