import styled from "styled-components";
import fotoMainImg from "../assets/images/background-servicos2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import CardSetores from "./CardSetores";
import icone_2 from "../assets/images/icone_2.png";
import icone_3 from "../assets/images/icone_3.png";
import icone_4 from "../assets/images/icone_4.png";
import icone_5 from "../assets/images/icone_5.png";
import icone_6 from "../assets/images/icone_6.png";
import icone_7 from "../assets/images/icone_7.png";

const LogoContainer = styled.div`
  background: url(${(props) => props.fotoMainImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 210vh;
  color: #ececec;
  margin-top: -15%;
  @media only screen and (max-width: 600px) {
    height: 240vh;
  }
`;
const MainContainer = styled.div`
  padding-top: 16%;
  margin-left: 8%;
`;

const Titulo = styled.div`
  font: 600 60px "Montserrat-Bold", sans-serif;
  color: #fa4616;
  @media (min-width: 1537px) {
    font: 600 78px "Montserrat-Bold", sans-serif;
  }
  @media only screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const Texto = styled.div`
  padding-top: 2%;
  width: 70%;
  font: 600 25px "Montserrat-SemiBold", sans-serif;
  color: #ececec;
  @media (min-width: 1537px) {
    font: 600 26px "Montserrat-SemiBold", sans-serif;
  }
  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;
const CardGroup = styled.div`
  display: flex;
  gap: 4%;
  margin-top: 5%;
  text-align: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-top: 0;
  }
`;

const TextoLink = styled.a`
  color: #fa4616;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function SetoresMain() {
  AOS.init();

  return (
    <LogoContainer fotoMainImg={fotoMainImg}>
      <MainContainer>
        <Titulo data-aos="fade-down" data-aos-duration="1500">
          {" "}
          Setores de atuação
        </Titulo>
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Atendemos diversos setores do comércio e da indústria, com certeza seu
          negócio está sob nossa expertise.
        </Texto>
        <br />
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Setores como:
        </Texto>

        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardSetores imagem={icone_2} texto="Indústria alimentícia" />
          <CardSetores
            texto="Materiais de construção"
            imagem={icone_3}
            textColor="#fa4616"
          />
          <CardSetores
            texto="Indústrias mineradoras"
            imagem={icone_4}
            textColor="#fa4616"
          />
          <CardSetores
            texto="Setor de mudanças"
            imagem={icone_5}
            textColor="#fa4616"
          />
        </CardGroup>
        <CardGroup data-aos="fade-down" data-aos-duration="1500">
          <CardSetores
            texto="Cargas em containers"
            imagem={icone_6}
            textColor="#fa4616"
          />
          <CardSetores
            texto="Insumos para grandes indústrias"
            imagem={icone_7}
            textColor="#fa4616"
          />
        </CardGroup>
        <br />
        <br />
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Não atendemos seu setor?
        </Texto>
        <Texto data-aos="fade-down" data-aos-duration="1500">
          Provavelmente apenas não o citamos aqui, &nbsp;
          <TextoLink
            target="_blank"
            href="https://api.whatsapp.com/send?phone=558596907171&text=N%C3%A3o%20encontrei%20o%20servi%C3%A7o%20que%20procurava."
          >
            nos chame
          </TextoLink>
          &nbsp;e esclareceremos suas dúvidas.
        </Texto>
      </MainContainer>
    </LogoContainer>
  );
}

export { SetoresMain };
export default SetoresMain;
