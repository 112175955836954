import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages";
import { ServicosPage } from "./pages";
import { SetoresPage } from "./pages";
import { FrotaPage } from "./pages";
import { Analytics } from "@vercel/analytics/react";

function AppRouter() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/servicos" exact element={<ServicosPage />} />
          <Route path="/setores" exact element={<SetoresPage />} />
          <Route path="/frotas" exact element={<FrotaPage />} />
        </Routes>
      </BrowserRouter>
      <Analytics />
    </>
  );
}

export default AppRouter;
